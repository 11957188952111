import './Home.scss';
import { Link } from 'react-router-dom';
import Hero from '../hero/Hero';
import ServiceBlock from '../ServiceBlock/ServiceBlock';
import { getPrimaryServices } from '../../data';
import { Helmet } from 'react-helmet';
import Notice from '../notice/Notice';

const Home = () => {
    // const heroUrl = 'https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/hero-images%2Fpexels-johannes-plenio-1103970.jpg?alt=media&token=cb7971c5-4d24-4f35-954f-120a1224c19f';
    const logoUrl = 'https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/logo%2Flogo-big.png?alt=media&token=0c2e8d84-ee56-4915-b39c-b449cd6c2083';
    const services = getPrimaryServices();
    const heroUrl = 'https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/staff-photos%2Fstaff-home.jpg?alt=media&token=5ed5b75d-2044-4a9e-b65b-8a006f33d0d5'
    return (
        <main className="home">
            <Helmet>
                <title>Kreklow Dentistry</title>
                <meta
                    name="description"
                    content="Dr. Kreklow Family Dentistry in Grand Rapids, MN. Offering quality restorative, prosthodontic, and cosmetic dentistry. Call 218-326-1266 for more info."
                    />
                <link rel="canonical" href="https://kreklowdental.com" />
            </Helmet>
            <Hero position='top' url={heroUrl} title='Quality restorative, prosthodontic, and cosmetic dentistry.' showLogo={false}/>
            <section className="services-description">
                {services && services.map((s, i) => <ServiceBlock key={i} service={s}/>)}
                <Link to="/services" className="button">View Our Services</Link>
            </section>
{/* 
            <section className="bottom-hero">
                <img src={bottomUrl} alt="Kreklow Dental Family Dentistry, Grand Rapids MN" />
            </section> */}
        </main>
    )
}

export default Home;