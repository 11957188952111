import './Hours.scss';

const Hours = () => {
    return (
        <section className="hours flex-center">
            <h2>Hours</h2>
            <ul>
                <li>
                    <span>Mon</span>
                    <span>8:00 am - 5:00 pm</span>
                </li>
                <li>
                    <span>Tue</span>
                    <span>8:00 am - 5:00 pm</span>
                </li>
                <li>
                    <span>Wed</span>
                    <span>8:00 am - 5:00 pm</span>
                </li>
                <li>
                    <span>Thu</span>
                    <span>8:00 am - 5:00 pm</span>
                </li>
                <li>
                    <span>Fri</span>
                    <span>- By Appointment</span>
                </li>
            </ul>
        </section>
    )
}

export default Hours;