export type Bio = {
    title: string;
    name: string;
    bio: string[];
    img_url?: string;
}

export const BIOS: Bio[] = [
    {
        title: 'General Dentist',
        name: 'Dr. Gretchen Kreklow',
        bio: [
            'Dr. Gretchen Kreklow grew up in Brainerd, MN. She received her DDS degree at the University of Minnesota School of Dentistry in 2020. She started practicing in Grand Rapids in June of 2021. Her objective is to provide high quality care in a comfortable environment for all patients. She is a member of the Minnesota Dental Association and the American Dental Association. She thoroughly enjoys learning all of the latest in dentistry.',
            'Outside of dentistry Dr. Kreklow enjoys running, cross country skiing, fishing, baking and travelling. She enjoys spending time with her husband, Jason, her Bernese Mountain Dog, Bastian, and all of her friends and family.',
            'Dr. Kreklow looks forward to meeting and providing satisfactory dental care to you and your family.'
        ],
        img_url: 'https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/staff-photos%2Fsmaller%2Fgretchen-sm.jpg?alt=media&token=0548dcb7-92fd-4c19-84e2-10e0d7459f1d'
    },
    {
        title: 'Registered Dental Hygienist',
        name: 'Roxanne',
        bio: [
            `Roxanne graduated from University of Minnesota, Duluth with a Dental Hygiene degree in 1993. She is certified in Expanded Duties in nitrous oxide, local anesthesia and laser. Roxanne has been practicing with Rowell Family Dentistry since July of 1993.
            She enjoys spending time with family and friends, the outdoors, and watching her boys play hockey.
            `
        ],
        img_url: 'https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/staff-photos%2Fsmaller%2Froxanne-sm.jpeg?alt=media&token=91816d00-339a-41dc-8bce-7a107ac32b1a'
    },
    {
        title: 'Licensed Dental Assistant',
        name: 'Sarah',
        bio: [
            `Sarah grew up in Grand Rapids, where she graduated from Grand Rapids High School in 2008. Sarah continued her education, graduating from Hibbing Community College as a Licensed Dental Assistant in 2010. Shortly after graduating, she joined our team. Sarah and her husband live on the Iron Range with their children. Sarah enjoys camping, hunting, four-wheeling, and spending time with family and friends.`
        ],
        img_url: 'https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/staff-photos%2Fsarah2.jpg?alt=media&token=54d659e2-9c1d-4baa-8888-57e79ce12fc8'
    },
    {
        title: 'Licensed Dental Assistant/Restorative Expanded Functions',
        name: 'Kallie',
        bio: [
            `Kallie was born and raised in Grand Rapids, graduating from Grand Rapids High School in 2001. She obtained her Dental Assisting Diploma from Hibbing Community College in 2004 and returned to the University of Minnesota in 2011 and earned her Restorative Expanded Functions Certificate. This training has allowed Kallie to place quality dental restorations on patients. Kallie is very passionate about dentistry and is very interested in the newest technology. She loves attending continuing education in order to keep up with the latest advances in dentistry.`,
            `Kallie is married to her high school sweetheart, Danny. Together they have three children, Haven, Eylee, and Levi. She enjoys fishing, hunting, boating, 4-wheeling, and any time spent with family.`
        ],
        img_url: 'https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/staff-photos%2Fsmaller%2Fkallie-sm.jpeg?alt=media&token=1990b1b1-7220-4c64-8dd1-0ea2b0af87ce'
    },
    {
        title: 'Registered Dental Hygienist',
        name: 'Laura',
        bio: [
            `Laura began her career in the dental field in 2014 with dental assisting and graduated from Lake Superior college in 2018 with her dental hygiene AAS. She has been practicing dental hygiene for 3 years. She enjoys traveling the world with her husband Patrick and summer days enjoying the sunshine.`
        ],
        img_url: 'https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/staff-photos%2Fsmaller%2Flaura-sm.jpg?alt=media&token=f82da454-e93f-469a-93ac-6d646d698b71'
    },
    {
        title: 'Licensed Dental Assistant/ Restorative Expanded functions',
        name: 'Dina',
        bio: [
            `Dina recently relocated to Grand Rapids after living in the small town of Ely for 4 years. She originally grew up in the beautiful islands of the Pacific Northwest in Washington State. After receiving her dental assisting diploma from Hibbing Community College in 2019, her passion for the dental industry led her to continue her education at the University of Minnesota, where she achieved her restorative expanded functions certificate.`,
            `Dina loves to spend time with her family and dog named Drake. When she is not at work she’s usually traveling for her kids' sports events or enjoying the outdoors.`
        ],
        img_url: 'https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/staff-photos%2Fsmaller%2Fdina-sm.jpeg?alt=media&token=6bf3b59f-a894-499c-a8cc-fbd38149bec4'
    },
    {
        title: 'Registered Dental Hygienist',
        name: 'Katie',
        bio: [
            `Katie graduated from Lake Superior College in 2000 with her dental hygiene AAS degree. She moved to Grand Rapids from Oshkosh, WI in 2008. Prior to coming to Grand Rapids,
            Katie had worked mostly in Wisconsin and then due to her husband’s job she also traveled to South Carolina and worked a year there. She then chose to stay home for a few years when starting her family. Katie and her husband Aaron have two teenage girls, Aubrey and Karley.`,
            `Katie enjoys hiking, snow shoeing, weight training and her hobbies are sewing, crafting and
            cooking. She loves being a hygienist and is passionate about educating her patients. Katie
            joined the team February 2022 and feels right at home here. She looks forward to coming to
            work every day to share her passion for dental hygiene.`
        ],
        img_url: 'https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/staff-photos%2Fkatie.jpg?alt=media&token=0e665a61-5deb-4db6-a493-e500e4e52270'
    },
    {
        title: 'Treatment Coordinator/Licensed Dental Assistant',
        name: 'Hollie',
        bio: [
            `Hollie grew up in Zim, MN and later moved to Grand Rapids where she has lived a majority of her life. She joined our team with over 12 years of experience in the dental field and an additional 20+ years of customer service. She has held many key positions throughout her career, from anesthesia assisting to patient care coordination.`,
            `She has a strong passion for patient care, dentistry, and continuing education.
            Outside the office Hollie is a mom of 5 boys. Jacob, Christian, Mathias, Eli, and Bennett. Some of the things she enjoys doing with her husband, family, and friends are camping, fishing, traveling, snowmobiling, and crafting.`,
            `Her smile will welcome you as you enter our practice and be available to help answer insurance and billing questions. She thrives to help the office run smoothly by helping patients maintain their dental appointments, manage finance and insurance plans.`
        ],
        img_url: 'https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/staff-photos%2Fhollie.jpg?alt=media&token=12b90b52-a0ae-4d1f-a888-7c2d567d1b16'
    },

]