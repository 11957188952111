import './Services.scss';
import { SERVICES } from '../../data';
import Hero from '../hero/Hero';
import ServiceBlock from '../ServiceBlock/ServiceBlock';
import { Helmet } from 'react-helmet'

const Services = () => {
    const serviceGroup1 = SERVICES.slice(0, 4);
    const serviceGroup2 = SERVICES.slice(4, 8);
    const serviceGroup3 = SERVICES.slice(8, 12);
    const serviceGroup4 = SERVICES.slice(12);

    return (
        <main className="services">
            <Helmet>
                <title>Services | Kreklow Dentistry</title>
                <meta name="description" content="Kreklow Dentistry in Grand Rapids, MN provides high quality dental care in a comfortable and welcoming environment for all patients. Browse all the services offered at Kreklow Dental." />
                <link rel="canonical" href="https://kreklowdental.com/services" />
            </Helmet>
            <Hero title="Services" url='https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/hero-images%2Fservices-blurred.png?alt=media&token=30ea7525-c181-4105-b752-6597814d6cc0' position="top"/>

            <section className="service-blocks">
                <div className="service-block-img">
                    <img src="https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/hero-images%2Fservices2.png?alt=media&token=62c2f744-cd32-4664-b1ce-c75010d3973c" alt="Kreklow Dental staff member" />
                </div>
                <div>
                    {serviceGroup1.map((s, i) => <ServiceBlock service={s} hideIcons={true} key={i} />)}
                </div>
            </section>

            <section className="service-blocks">
                <div>
                    {serviceGroup2.map((s, i) => <ServiceBlock service={s} hideIcons={true} key={i} />)}
                </div>
                <div className="service-block-img">
                    <img src="https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/hero-images%2Fservices1.png?alt=media&token=e81a468c-a734-41c6-8966-6e8a0f292171" alt="Kreklow Dental staff member" />
                </div>
            </section>

            <section className="service-blocks">
                <div className="service-block-img">
                    <img src="https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/hero-images%2Fservices4.png?alt=media&token=32ab139e-bb2f-4a9f-be6b-d1b911299094" alt="Kreklow Dental staff member" />
                </div>
                <div>
                    {serviceGroup3.map((s, i) => <ServiceBlock service={s} hideIcons={true} key={i} />)}
                </div>
            </section>

            <section className="service-blocks">
                <div>
                    {serviceGroup4.map((s, i) => <ServiceBlock service={s} hideIcons={true} key={i} />)}
                </div>
                <div className="service-block-img">
                    <img src="https://firebasestorage.googleapis.com/v0/b/kreklow-dental.appspot.com/o/hero-images%2Fservices3.png?alt=media&token=948782f6-cb1a-46de-8102-3c75c0e8fbfb" alt="Kreklow Dental staff member" />
                </div>
            </section>
{/* 
            <section className="service-blocks">
                <div className="service-block-img">
                    <img src="" alt="" />
                </div>
                <div>
                    {serviceGroup5.map((s, i) => <ServiceBlock service={s} hideIcons={true} key={i} />)}
                </div>
            </section> */}
        </main>
    )
}
export default Services;